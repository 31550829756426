import { createAjaxWithCredentials } from '../../../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../../../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../../../_shared/utils/urlFactory.ts';
import { RepositoryWithContext } from '../../../repositories/interfaces/repository.type.ts';
import {
  ListingServerResponseModel,
  ensureAllRequestedDataFetched,
} from '../../../repositories/utils/ensureAllRequestedDataFetched.ts';
import { TimeInterval } from '../constants/timeIntervals.ts';
import { PublishedItemsCountRequestModel } from '../widgets/publishedItems/types/PublishedItemsCountRequestModel.type.ts';
import { PublishedItemsListingRequestOptionsModel } from '../widgets/publishedItems/types/PublishedItemsListingRequestOptionsModel.type.ts';
import { UnchangedPublishedItemsListingRequestOptionsModel } from '../widgets/unchangedPublishedContent/types/UnchangedPublishedItemsListingRequestOptionsModel.type.ts';
import { MissionControlRepository } from './MissionControlRepository.type.ts';
import {
  ContentStatusFilterRepositoryServerModel,
  CountsByDueDateServerModel,
  DoneOnTimeServerModel,
  PublishedItemsServerModel,
} from './serverModels/missionControlRepositoryServerModels.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

const getItemsRoute = (endpoint: string) => `mission-control-widget/items/${endpoint}`;
const getTasksRoute = (endpoint: string) => `mission-control-widget/tasks/${endpoint}`;
const getWorkflowStatisticsRoute = (endpoint?: string) =>
  `mission-control-widget/workflow-statistics${endpoint ? `/${endpoint}` : ''}`;
const getItemIdsWithTimeSpentInWorkflowStepRoute = (workflowId: Uuid, stepId: Uuid) =>
  getWorkflowStatisticsRoute(`${workflowId}/steps/${stepId}/listing-item-ids`);

export const missionControlRepository: RepositoryWithContext<MissionControlRepository> = {
  getTasksCreatedByCurrentUser(requestContext, listingRequestOptions, abortSignal?) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/${getTasksRoute('created-by-me')}`;
    return ensureAllRequestedDataFetched(
      (requestOptions) => restProvider.post(url, requestOptions, abortSignal, requestContext),
      listingRequestOptions,
      abortSignal,
    );
  },
  getOverdueTasks(requestContext, listingRequestOptions, abortSignal?) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/${getTasksRoute('overdue')}`;
    return ensureAllRequestedDataFetched(
      (requestOptions) => restProvider.post(url, requestOptions, abortSignal, requestContext),
      listingRequestOptions,
      abortSignal,
    );
  },
  async getItemsPublishedOnTime(requestContext, timeInterval, abortSignal?) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/${getItemsRoute('published-on-time')}`;
    const result: DoneOnTimeServerModel = await restProvider.post(
      url,
      { timeInterval },
      abortSignal,
      requestContext,
    );
    return result;
  },
  getContentPace(requestContext, query, abortSignal?) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/${getWorkflowStatisticsRoute()}`;
    return restProvider.post(
      url,
      {
        contentTypeId: query.contentTypeId,
        isFirstVariant: query.isFirstVariant,
        publishingStage: query.publishingStage,
        timeInterval: query.timeInterval,
      },
      abortSignal,
      requestContext,
    );
  },
  getItemIdsWithTimeSpentInWorkflowStep(requestContext, workflowId, stepId, query, abortSignal?) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/${getItemIdsWithTimeSpentInWorkflowStepRoute(workflowId, stepId)}`;
    return restProvider.post(
      url,
      {
        contentTypeId: query.contentTypeId,
        isFirstVariant: query.isFirstVariant,
        publishingStage: query.publishingStage,
        timeInterval: query.timeInterval,
      },
      abortSignal,
      requestContext,
    );
  },
  getContentStatusItems(
    requestContext: IRequestContext,
    filter: ContentStatusFilterRepositoryServerModel,
    abortSignal?: AbortSignal,
  ) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/content-status-item-list/query`;
    return restProvider.post(url, filter, abortSignal, requestContext);
  },
  async getContentProgress(requestContext: IRequestContext, abortSignal?: AbortSignal) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/${getItemsRoute('counts-by-due-date')}`;
    const result: CountsByDueDateServerModel = await restProvider.post(
      url,
      null,
      abortSignal,
      requestContext,
    );
    return result;
  },
  async getTasksDoneOnTime(
    requestContext: IRequestContext,
    timeInterval: TimeInterval,
    abortSignal?: AbortSignal,
  ) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/${getTasksRoute('done')}`;
    const result: DoneOnTimeServerModel = await restProvider.post(
      url,
      { timeInterval },
      abortSignal,
      requestContext,
    );
    return result;
  },
  getUnresolvedCommentCounts(requestContext, contentItemIds, abortSignal?) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/content-status-item-list/comments/active/count-by-item-ids`;
    const query = {
      contentItemIdentifiers: contentItemIds,
    };
    return restProvider.post(url, query, abortSignal, requestContext);
  },
  getUnresolvedTaskCounts(requestContext, contentItemIds, abortSignal?) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/content-status-item-list/tasks/count-by-item-ids`;
    const query = {
      contentItemIdentifiers: contentItemIds,
      status: 'Open',
    };
    return restProvider.post(url, query, abortSignal, requestContext);
  },
  getUnchangedUnpublishedItems(requestContext, listingRequestOptions, abortSignal?) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/${getItemsRoute('unchanged-unpublished')}`;
    return ensureAllRequestedDataFetched(
      (requestOptions) => restProvider.post(url, requestOptions, abortSignal, requestContext),
      listingRequestOptions,
      abortSignal,
    );
  },
  getUnchangedPublishedItems(
    requestContext: IRequestContext,
    listingRequestOptions: UnchangedPublishedItemsListingRequestOptionsModel,
    abortSignal?: AbortSignal,
  ) {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/${getItemsRoute('unchanged-published')}`;

    return ensureAllRequestedDataFetched(
      (requestOptions) => restProvider.post(url, requestOptions, abortSignal, requestContext),
      listingRequestOptions,
      abortSignal,
    );
  },
  getPublishedItems(
    requestContext: IRequestContext,
    listingRequestOptions: PublishedItemsListingRequestOptionsModel,
    abortSignal?: AbortSignal,
  ): Promise<ListingServerResponseModel<PublishedItemsServerModel>> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/${getItemsRoute('published')}`;
    return restProvider.post(url, listingRequestOptions, abortSignal, requestContext);
  },
  getPublishedItemsCount(
    requestContext: IRequestContext,
    query: PublishedItemsCountRequestModel,
    abortSignal?: AbortSignal,
  ): Promise<number> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/${getItemsRoute('published-count')}`;
    return restProvider.post(url, query, abortSignal, requestContext);
  },
};
